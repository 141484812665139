import * as React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title={siteTitle} />

      <section>
        <p>
          Hi there! I'm Eliot and this is my digital garden, aka my personal
          journal that I've decided to share with you and anyone else who
          visits. As a heads up, some or all of the posts here may be
          half-finished or works-in-progress.
        </p>

        <p>
          Actually, that's the whole point. My hope is that by lowering the bar
          for sharing content will make it easier to share raw thoughts. And
          that by doing so can help me learn in public with anyone who pops by.
        </p>

        <p>
          Given that this is my personal home and not a business or professional
          space, the content here isn't really meant to be navigated
          chronologically or even linearly. With that said, here are some good
          places to start.
        </p>
      </section>

      <section>
        <h3>My favorites:</h3>
        <ul>
          <li>
            <a href="/random-remote-images">Random Remote Images in Swift</a>
          </li>
        </ul>
      </section>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { slug: { ne: null } } }
    ) {
      nodes {
        excerpt
        frontmatter {
          date(formatString: "YYYY-MM-DD")
          title
          description
          slug
        }
      }
    }
  }
`
